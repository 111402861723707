import React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';

type Props = {
  buttons: Array<any>,
  error: ?string,
  message: string,
  show: boolean,
  title: string,
  handleClose: any,
};

export default (props: Props) => {
  const { buttons, error, handleClose, message, show, title } = props;
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p dangerouslySetInnerHTML={{__html: message}} />
        <Alert variant="danger" show={!!error}>{error}</Alert>
      </Modal.Body>
      <Modal.Footer>
        {buttons.map((button, i) => <Button key={i} variant={button.variant} onClick={button.handler}>{button.label}</Button>)}
      </Modal.Footer>
    </Modal>
  );
};
