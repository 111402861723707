import gql from 'graphql-tag';

export default gql`
mutation UpdateCompany($input: UpdateCompanyInput!) {
  updateCompany(input:$input) {
    company {
      id
      name
      businessName
      address
      ein
    }
  }
}
`;
