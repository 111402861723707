import gql from 'graphql-tag';

export default gql`
query GetCompany($id: ID!) {
  company(id: $id) {
    id
    name
    address
    ein
    businessName
  }
}
`;
