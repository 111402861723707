import gql from 'graphql-tag';

export default gql`
mutation DeleteCompany($input: DeleteCompanyInput!) {
  deleteCompany(input:$input) {
    success
    companyId
  }
}
`;
