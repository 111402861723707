import React, { useState } from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  BorderBox,
  TextInput,
  Flex
} from '@primer/components';
import styled from 'styled-components';

import ButtonPrimary from '../../components/ButtonPrimary';

const StyledFlex = styled(Flex)`
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Login = () => {
  const [token, setToken] = useState('');
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };
  const submit = ev => {
    ev.preventDefault();
    localStorage.setItem('token', token);
    history.replace(from);
  };

  return (
    <StyledFlex>
      <BorderBox>
        <form onSubmit={submit}>
          <div>
            <TextInput aria-label="Token" placeholder="Token" value={token} onChange={ev => setToken(ev.target.value)} />
          </div>
          <ButtonPrimary variant="large">Login</ButtonPrimary>
        </form>
      </BorderBox>
    </StyledFlex>
  );
}

export default Login;
