import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { ErrorMessage, Form as FormikForm, Formik } from 'formik';
import React, { useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import FieldErrorMessage from '../../../components/ErrorMessage';

import CREATE_COMPANY from '../gql/createCompany';
import UPDATE_COMPANY from '../gql/updateCompany';
import GET_COMPANIES from '../gql/getCompanies';
import GET_COMPANY from '../gql/getCompany';

type Props = {
  companyId: ?string,
  onFinish: any,
  show: boolean,
};

export default (props: Props) => {
  const { companyId, onFinish, show } = props;
  const [loadCompany, { loading, data }] = useLazyQuery(
    GET_COMPANY
  );

  useEffect(() => {
    if (companyId) {
      loadCompany({
        variables: {
          id: companyId,
        }
      });
    }
  }, [companyId]);

  const [createCompany] = useMutation(
    CREATE_COMPANY,
    {
      update(cache, { data: { createCompany } }) {
        const { companies } = cache.readQuery({ query: GET_COMPANIES });
        const index = companies.edges.findIndex(({ node }) => createCompany.company.name < node.name);
        companies.edges.splice(index, 0, {
          __typename: 'CompanyEdge',
          node: createCompany.company,
        });
        cache.writeQuery({
          query: GET_COMPANIES,
          data: {
            companies,
          }
        });
      }
    }
  );

  const [updateCompany] = useMutation(
    UPDATE_COMPANY,
    {
      update(cache, { data: { createCompany } }) {
        const { companies } = cache.readQuery({ query: GET_COMPANIES });
        companies.edges.sort((a, b) => {
          console.log('a, b')
          console.log(a.node, b.node)
          if (a.node.name < b.node.name) {
            return -1;
          } else if (a.node.name > b.node.name) {
            return 1;
          } else {
            return 0;
          }
        });
        console.log(JSON.stringify(companies, null, 2))
        cache.writeQuery({
          query: GET_COMPANIES,
          data: {
            companies,
          }
        });
      }
    }
  );

  const initialValues = companyId && data && data.company
  ? {
      name: data.company.name,
      address: data.company.address,
      ein: data.company.ein,
      businessName: data.company.businessName,
    }
  : {
      name: '',
      address: '',
      ein: '',
      businessName: '',
    };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onFinish}
      aria-labelledby="company-form-title"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="company-form-title">
          {!companyId ? 'New' : 'Edit'} Company
        </Modal.Title>
      </Modal.Header>
      {show && (loading
        ? <Modal.Body>Loading</Modal.Body>
        : (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validate={values => {
              const errors = {};
              if (!values.name) {
                errors.name = 'Required';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              const mutation = companyId
                ? updateCompany({
                    variables: {
                      input: {
                        ...values,
                        companyId: data.company.id,
                      }
                    }
                  })
                : createCompany({ variables: { input: values }});
              return mutation
              .then(onFinish);
            }}
          >
            {({
              errors,
              handleChange,
              isSubmitting,
              resetForm,
              values,
            }) => {
              return (
                <FormikForm>
                  <Modal.Body>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name"
                        onChange={handleChange}
                        value={values.name}
                      />
                      <ErrorMessage name="name">{msg => <FieldErrorMessage>{msg}</FieldErrorMessage>}</ErrorMessage>
                      {/* <ErrorMessage>{errors.name && touched.name && errors.name}</ErrorMessage> */}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="businessName"
                        placeholder="Business Name"
                        onChange={handleChange}
                        value={values.businessName}
                      />
                      <ErrorMessage name="businessName">{msg => <div>{msg}</div>}</ErrorMessage>
                      {/* <ErrorMessage>{errors.password && touched.password && errors.password}</ErrorMessage> */}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>EIN</Form.Label>
                      <Form.Control
                        type="text"
                        name="ein"
                        placeholder="EIN"
                        onChange={handleChange}
                        value={values.ein}
                      />
                      <ErrorMessage name="ein">{msg => <div>{msg}</div>}</ErrorMessage>
                      {/* <ErrorMessage>{errors.ein && touched.ein && errors.ein}</ErrorMessage> */}
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="address"
                        rows="3"
                        placeholder="Address"
                        onChange={handleChange}
                        value={values.address}
                      />
                      <ErrorMessage name="address">{msg => <div>{msg}</div>}</ErrorMessage>
                      {/* <ErrorMessage>{errors.address && touched.address && errors.address}</ErrorMessage> */}
                    </Form.Group>
                    {/* <Button type="button" variant="secondary" onClick={onFinish}>Cancel</Button>
                    <Button variant="primary" type="submit" disabled={isSubmitting}>Submit</Button>
                    <Button align="center" variant="primary" type="submit">Submit</Button> */}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button type="button" variant="secondary" onClick={onFinish}>Cancel</Button>
                    <Button variant="primary" type="submit" disabled={isSubmitting}>Submit</Button>
                  </Modal.Footer>
                </FormikForm>
              )
            }}
          </Formik>
        )
      )
      }

    </Modal>
  );
};
