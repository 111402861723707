import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

import client from './apollo/client'
import Routes from './Routes';

const theme = {
  // primaryColor: 'rgb(40, 167, 69)',
};

const App = () => {
  return (
    <Router>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
         <Routes />
        </ThemeProvider>
      </ApolloProvider>
    </Router>
  );
}

export default App;
