import React from 'react';
import { Route, Switch } from 'react-router-dom';

import InvoicesList from './InvoicesList';

export default () => (
  <Switch>
    <Route exact path='/invoices' component={InvoicesList}/>
    {/* <Route path='/titles/:id' component={Title}></Route> */}
  </Switch>
);
