import React from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import Companies from './containers/companies/routes';
import Invoices from './containers/invoices/routes';
import Login from './containers/login';
import Layout from './containers/layout';
// import ExamplePageOne from '../containers/example/ExamplePageOne';
// import ExamplePageTwo from '../containers/example_two/ExamplePageTwo';

// import DefaultDashboard from '../containers/dashboards/default';

const Routes = () => (
  <Switch>
    <Route path="/login">
      <Login />
    </Route>
    <Route path='/'>
      <WrappedRoutes />
    </Route>
  </Switch>
);

const WrappedRoutes = () => {
  return localStorage.getItem('token')
    ? <Layout>
        <Route exact path='/' component={DefaultDashboard} />
        <Route path='/companies' component={Companies} />
        <Route path='/invoices' component={Invoices} />
      </Layout>
    : <Redirect to='/login' />;
};

const DefaultDashboard = () => {
  return (
    <div>
      <p>{localStorage.getItem('token')}</p>
    </div>
  )
};

// const Pages = () => (
//   <Switch>
//     <Route path='/pages/one' component={ExamplePageOne}/>
//     <Route path='/pages/two' component={ExamplePageTwo}/>
//   </Switch>
// );

export default Routes;
