import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag'
import React from 'react';

import Card from '../../components/Card';

const GET_INVOICES = gql`
query {
  invoices {
    nodes {
      description {
        description
        unitPrice
      }
    }
  }
}
`;

const InvoicesList = () => {
  const { data, error} = useQuery(GET_INVOICES);
  return (
    <>
    <h1>Invoices</h1>
    <Card>
      <p>{localStorage.getItem('token')}</p>
      <pre>{JSON.stringify(data, null, 2)}</pre>
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </Card>
    </>
  );
};

export default InvoicesList;
