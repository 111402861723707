import React from 'react';
import { Col, Container, Nav, Navbar, Row }  from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

// const Wrapper = styled(Container)`
//   // background-color: #f2f4f7;
//   height: 100%;
// `;

// const Main = styled.main`
//   flex: 1;
//   padding: 2em;
// `;

// const Sidebar = styled(Col)`
//   // background-color: white;
//   // box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
//   // width: 15em;
// `;

const NavSidebar = styled.nav`
  margin: 1em;
`;

const Menu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.li`
  padding: 0.4em 0;

  .active {
    font-weight: bold;
  }
`;

const Layout = ({ children}) => {
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Nav className="mr-auto">
        </Nav>
        <Nav>
          <Nav.Link onClick={() => localStorage.removeItem('token')}>Log out</Nav.Link>
        </Nav>
      </Navbar>


      <Container fluid>
        <Row>
          <Col as="nav" className="bg-light" sm={12} md={3} xl={2}>
            <NavSidebar>
              <Menu>
                <MenuItem><NavLink to="/companies">Companies</NavLink></MenuItem>
                <MenuItem><NavLink to="/invoices">Invoices</NavLink></MenuItem>
              </Menu>
            </NavSidebar>
          </Col>
          <Col as="main">
            <Container fluid>
              {children}
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Layout;
