import React, { useState } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';

import CompaniesTable from './components/CompaniesTable';
import CompanyForm from './components/CompanyForm';

export default () => {
  const [modal, setModal] = useState([false, null]);


  const openModal = (id) => {
    setModal([true, id]);
  };

  const closeModal = () => {
    setModal([false, null]);
  };

  return (
    <>
      <h1>Companies</h1>
      <ButtonToolbar style={{ justifyContent: 'flex-end', padding: '1em 0' }}>
        <Button variant="primary" onClick={() => openModal()}>Create Company</Button>
      </ButtonToolbar>
      <CompaniesTable openModal={openModal} />
      <CompanyForm show={modal[0]} companyId={modal[1]} onFinish={closeModal} isNew={true} />
    </>
  );
};
