import styled from 'styled-components';

export default styled.button`
  background-color: #f6f8fa;
  background-image: linear-gradient(-180deg, #fafbfc 0%,rgb(239,243,246) 90%);
  background-repeat: repeat-x;
  background-position: -1px -1px;
  background-size: 110% 110%;
  border: 1px solid rgba(27,31,35,0.2);
  border-radius: 3px;
  color: #24292e;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  position: relative;
  padding: 6px 12px;
  vertical-align: middle;
  white-space: nowrap;

  :hover {
    background-color: rgb(230,235,241);
    background-image: linear-gradient(-180deg, rgb(239,243,246) 0%, rgb(230,235,241) 90%);
    background-position: -0.5em center;
    border-color: rgba(27,31,35,0.35);
    text-decoration: none;
    background-repeat: repeat-x;
  }

  :active {
    background-color: rgb(233,236,239);
    background-image: none;
    box-shadow: rgba(27,31,35,0.15) 0px 0.15em 0.3em inset;
    border-color: rgba(27,31,35,0.2);
  }
`;
