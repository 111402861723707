import gql from 'graphql-tag';

export default gql`
query GetCompanies {
  companies(
    first: 100
  ) {
    edges {
      node {
        id
        name
        ein
        businessName
        address
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`;
