import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CompaniesList from './CompaniesList';
// import CompanyForm from './CompanyForm';

export default () => (
  <Switch>
    <Route exact path='/companies' component={CompaniesList}/>
    {/* <Route path='/companies/new' component={CompanyForm}></Route> */}
    {/* <Route path='/titles/:id' component={Title}></Route> */}
  </Switch>
);
