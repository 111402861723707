import gql from 'graphql-tag';

export default gql`
mutation CreateCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    company {
      id
      name
      ein
      businessName
    }
  }
}
`;
