import { useMutation, useQuery } from '@apollo/react-hooks';
import { faEdit, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Alert, Table } from 'react-bootstrap';
import styled from 'styled-components';

import ModalConfirm from '../../../components/ModalConfirm';
import DELETE_COMPANY from '../gql/deleteCompany';
import GET_COMPANIES from '../gql/getCompanies';

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin: 0 0.5em;
`;

export default ({ openModal }) => {
  const [deleting, setDeleting] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const deleteError = null;

  const handleCloseConfirm = () => setDeleteConfirmation(null);

  const { data, error, loading } = useQuery(GET_COMPANIES);

  const [deleteCompanyConfirmed] = useMutation(
    DELETE_COMPANY,
    {
      update(cache, { data: { deleteCompany } }) {
        const { companies } = cache.readQuery({ query: GET_COMPANIES });
        cache.writeQuery({
          query: GET_COMPANIES,
          data: {
            companies: {
              ...companies,
              edges: companies.edges.filter(({ node }) => node.id !== deleteCompany.companyId),
            }
          }
        });
      }
    }
  );

  const deleteCompany = () => {
    setDeleting(deleteConfirmation.id);
    deleteCompanyConfirmed({
      variables: {
        input: {
          companyId: deleteConfirmation.id,
        }
      }
    })
    .catch(err => {
      console.error(err)
      setDeleting(null);
    });
    setDeleteConfirmation(null)
  };

  if (loading) {
    return <p>Loading&hellip;</p>
  }
  if (error) {
    return (
      <Alert variant="danger">
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Alert>
    );
  }
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Ein</th>
            <th>Business Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.companies.edges.map(({ node }) => (
            <tr
              key={node.id}
            >
              <td><strong>{node.name}</strong></td>
              <td>{node.ein}</td>
              <td>{node.businessName}</td>
              <td>
                <Icon icon={faEdit} onClick={() => openModal(node.id)} />
                {deleting === node.id
                  ? <Icon icon={faSpinner} fixedWidth spin />
                  : <Icon icon={faTrashAlt} fixedWidth onClick={() => setDeleteConfirmation(node)} />
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ModalConfirm
        show={!!deleteConfirmation}
        title="Delete Company"
        message={`Do you really want to delete company <b>${!!deleteConfirmation ? deleteConfirmation.name : ''}</b>?`}
        handleClose={handleCloseConfirm}
        error={deleteError}
        buttons={[
          {
            variant: 'secondary',
            label: 'Cancel',
            handler: handleCloseConfirm,
          },
          {
            variant: 'danger',
            label: 'Delete',
            handler: deleteCompany,
          }
        ]}
      />
    </>
  );
};
