import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';

const uri = process.env.NODE_ENV === 'production'
  ? 'https://2ifgylsmw5btvfid72uharckca.appsync-api.us-east-1.amazonaws.com/graphql'
  : 'https://u22uokyxu5clxkcuflppmflve4.appsync-api.us-east-1.amazonaws.com/graphql';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = createHttpLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      'x-api-key': token || '',
    },
  };
});

const link = ApolloLink.from([
  errorLink,
  authLink,
  httpLink,
]);

const client = new ApolloClient({
  link,//: authLink.concat(link),
  cache: new InMemoryCache(),
});

export default client;
